// Import core framework
import Framework7 from "framework7/framework7.esm.bundle";
import "framework7-icons";
import  './common/lCalendar.min'
// import "./base/pdfh5/css/pdfh5.css";
import "material-design-icons/iconfont/material-icons.css";
import "framework7/css/framework7.ios.min.css";
import $ from "dom7";
import "./app.less";
import "./base/styles/reset.less";
import "./base/utils/go-debug.js";
// import "./base/pdfh5/js/pdf.js"
// import "./base/pdfh5/js/pdf.worker.js"
// import "./base/pdfh5/js/jquery-2.1.1.min.js"
import Pdfh5 from "./base/pdfh5/js/pdfh5.js"

import routes from "./routes";
import _config from "./jsonrpc_config";

import VConsole from "vconsole" //import vconsole
let vConsole = new VConsole();

// Theme
let theme = "auto";
if (document.location.search.indexOf("theme=") >= 0) {
  theme = document.location.search.split("theme=")[1].split("&")[0];
}

let isIOS = false
if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
  isIOS = true
} else if (/(Android)/i.test(navigator.userAgent)) {
  isIOS = false
}

function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
const code = getQueryString("code");
const userid = getQueryString("userid");
const kthrp_customer_code = getQueryString("kthrp_customer_code");
const source = getQueryString("source");
const router = getQueryString("router");

// Init app
const app = new Framework7({
  root: "#app",
  name: "KTHRP",
  id: "com.kthrp",
  language: "zh_CN",
  // Global Panel Settings
  panel: {
    swipe: "left"
  },
  // Global View Settings
  view: {
    iosDynamicNavbar: false,
    xhrCache: false
    // pushstate: true,
    // pushStateRoot: "http://localhost:3000/",
    // pushStateSeparator: ""
  },
  theme: "ios",
  // All Routes
  routes: routes,
  on: {
    init: function () {
      var self = this;
    }
  },
  // Global Data
  data: {
    isIOS,
    // Pdfh5,
    // _config 配置
    session: {
      userid: userid,
      code: code,
      kthrp_customer_code: kthrp_customer_code,
      source: source,
      router:router,
      lang: "zh_CN", //en_US
    },
    dingding_code: "", //识别钉钉用户code
    feishu_userid: "", //识别飞书用户code
    url: _config.jsonrpc.base_url + '/jsonrpc',
    base_url: _config.jsonrpc.base_url,
    dbname: _config.jsonrpc.jsonrpc_dbname,
    username: _config.jsonrpc.jsonrpc_username,
    userid: "",
    password: _config.jsonrpc.jsonrpc_password,
    flag: true,
    expense_data: {},
    asset_data: {},
    _config: _config,
    
    // jsonrpc_map 配置
    client_url: _config.jsonrpc_map ? _config.jsonrpc_map.base_url + '/jsonrpc_map' :'',
    client_base_url: _config.jsonrpc_map ? _config.jsonrpc_map.base_url :'',
    client_dbname: _config.jsonrpc_map ? _config.jsonrpc_map.jsonrpc_dbname :'',
    client_username: _config.jsonrpc_map ? _config.jsonrpc_map.jsonrpc_username:'',
    client_password: _config.jsonrpc_map ? _config.jsonrpc_map.jsonrpc_password:'',

    // get_user_id 接口返回的数据
    get_user_id_data:{}
  },

  // data_map_client: {
  //   session: {
  //     userid: userid,
  //     code: code,
  //     kthrp_customer_code: kthrp_customer_code,
  //     source: source,
  //     router:router,
  //     lang: "zh_CN", //en_US
  //   },
  //   dingding_code: "", //识别钉钉用户code
  //   feishu_userid: "", //识别飞书用户code
  //   url: map_client_config.jsonrpc.base_url + '/jsonrpc',
  //   base_url: map_client_config.jsonrpc.base_url,
  //   dbname: map_client_config.jsonrpc.jsonrpc_dbname,
  //   username: map_client_config.jsonrpc.jsonrpc_username,
  //   userid: "",
  //   password: map_client_config.jsonrpc.jsonrpc_password,
  //   flag: true,
  //   expense_data: {},
  //   asset_data: {},
  //   _config: map_client_config,
  // },

  // App root methods
  methods: {
    helloWorld: function () {
      app.dialog.alert("Hello World!");
    },
    changeLanuage: function () {},
    getQueryString: function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    view_file(e){
      let url = e.currentTarget.dataset.url
      const name = e.currentTarget.dataset.name
      const id = e.currentTarget.dataset.id
      if(/\.(png|jpg|gif|jpeg|webp)$/.test(name)){
        var photoBrowser =  app.photoBrowser.create({
          photos : [url],
          type: 'standalone'
        });
        photoBrowser.open()
      }else if(/\.(pdf)$/.test(name)){
        app.popup.open('.view_file_container')
        var pdfh5 = new Pdfh5('#view_file_container', {
          pdfurl: url,
          backTop:false
        });
      }else if(/\.(doc|docx|xls|xlsx)$/.test(name)){
        url = '/web/' + url.split('/web/')[1]
        const params = app.methods.getJsonrpcParams('ir.attachment','mobile_get_file_pdf',{
          file_url:url,
          file_name:name,
          key:id
        });
        app.preloader.show()
        app.request.postJSON(app.data.url, params, function (res) {
          console.log(res)
          app.preloader.hide()
          if(res.result.state == 'success'){
            app.popup.open('.view_file_container')
            var pdfh5 = new Pdfh5('#view_file_container', {
              pdfurl: "data:application/pdf;base64," + res.result.base64_url,
              backTop:false
            });
          }else{
            app.dialog.alert("预览失败!", "系统提示");
          }
        })
      }else{ // 不支持预览文件类型
        location.href = url
      }
    },
    getJsonrpcParams: function (model, method, params) {
      var wechatName = this.data.session.userid;
      var dbname = this.data.dbname;
      var userid = this.data.userid;
      var password = this.data.password;
      return {
        "jsonrpc": "2.0",
        "method": "2.0",
        "params": {
          service: "object",
          method: "execute",
          args: [
            dbname,
            userid,
            password,
            model,
            method,
            params
          ]
        }
      };
    },
  },
  dialog: {
    // set default title for all dialog shortcuts
    title: "KTHRP",
    // change default "OK" button text
    buttonOk: "确定",
    buttonCancel: "取消"
  },
  smartSelect: {
    popupCloseLinkText: "关闭",
    searchbarDisableText: "取消",
    sheetCloseLinkText: "完成",
  },
  calendar: {
    closeOnSelect: true,
    monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    monthNamesShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    dayNames: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    dayNamesShort: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    headerPlaceholder: "选择日期",
    toolbarCloseText: "完成",
  }
});

// Init/Create views

const indexView = app.views.create("#view-main", {
  url: "/main/"
});

// const leftView = app.views.create('.view-left', {
//   url: '/'
// });

// Login Screen Demo
$("#my-login-screen .login-button").on("click", function () {
  const username = $('#my-login-screen [name="username"]').val();
  const password = $('#my-login-screen [name="password"]').val();

  // Close login screen
  app.loginScreen.close("#my-login-screen");

  // Alert username and password
  app.dialog.alert("Username: " + username + "<br>Password: " + password);
});

window.app = app;
export default app;
