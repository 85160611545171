import $$ from "dom7";
import _ from "lodash";
import Framework7 from "framework7/framework7.esm.bundle";
import style from "../styles/approveddocument.less";
import html from "../pages/index.html";


function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

export default [{
  name: "approveddocument",
  path: "/approveddocument/",
  component: {
    template: html,
    style: style,
    data: () => {
      return {};
    },
    methods: {
      async batchApproval(){
        let task_ids = []
        let page = $$('.page[data-name="approveddocument-index"]')[0].f7Page;
        const comment = $("#comment").val();
        await $('.approveddocumentindex #tab1 .list li').each(function(){
          const isChecked = $(this).find('input').prop('checked')
          if(isChecked){
            task_ids.push($(this).find('.card a').data('task-id') * 1)
          }
        });
        if(!task_ids.length){
          return app.dialog.alert("请先选择要审批的单据!");
        }
        if (app.data.session.source === "dingding") {
          var model = "kthrp.dingding.interface"
        } else if (app.data.session.source === "feishu") {
          var model = "kthrp.feishu.map.interface"
        } else {
          var model = "kthrp.wechat.map.interface"
        }
        const batch_approval_params = this.getBatchApproveParams(
          task_ids,
          'batch_approve',
          comment,
          model
        );
        const actionText = this.kthrp_customer_code == 'chanyi' ? '批量通过' : '批量审批'
        page.app.dialog.confirm('你确定要' + actionText + '吗？', function () {
          app.preloader.show();
          page.app.request.postJSON(
            page.app.data.url,
            batch_approval_params,
            function (result_approve) {
              if (result_approve.result.result == "ok") {
                // page.router.back();
                var success_msg, error_msg;
                if (page.app.data.session.lang == "zh_CN") {
                  success_msg = "恭喜您,操作成功";
                  error_msg = "很遗憾,操作失败";
                } else if (page.app.data.session.lang == "en_US") {
                  success_msg = "Succeed";
                  error_msg = "Failed";
                }
                var toast = app.toast.create({
                  text: success_msg,
                  position: "top",
                  closeTimeout: 2000
                });
                app.preloader.hide();
                toast.open();
                // page.router.navigate("/approveddocument/", {
                //   reloadCurrent: true,
                //   ignoreCache: true
                // });
                page.router.back('/approveddocument/', {
                  force: true
                })
              } else {
                app.preloader.hide();
                app.dialog.alert(result_approve.result.error_msg)
                // var toast = app.toast.create({
                //   text: result_approve.result.error_msg,
                //   position: "top",
                //   closeTimeout: 2000
                // });
                // app.preloader.hide();
                // toast.open();
                // page.router.navigate("/approveddocument/", {
                //   reloadCurrent: true,
                //   ignoreCache: true
                // });
                page.router.back('/approveddocument/', {
                  force: true
                })
              }
            }
          );
        }, function () {
          page.app.swipeout.close(page.app.swipeout.el);
        });
      },
      checkAll(){
        const self = this
        setTimeout(function() {
          const allChecked = $('.checkAll-checkbox').prop('checked')
          $$('.approveddocumentindex #tab1 .list li').each(function(){
            $(this).find('input').prop('checked',allChecked)
          })

          self.$setState({
            showBatchApproval:allChecked
          })

        },50)
      },
      checkOne(){
        const self = this
        setTimeout(function() {
          let showBatchApproval = false
          let checkCount = 0
          $$('.approveddocumentindex #tab1 .list li').each(function(){
            if($(this).find('input').prop('checked')){
              showBatchApproval = true
              checkCount += 1
            }
          })
          $('.checkAll-checkbox').prop('checked',checkCount == $$('.approveddocumentindex #tab1 .list li').length)
          self.$setState({
            showBatchApproval:showBatchApproval
          })

        },50)
      },
      getApprovedList(){
        const self = this
        if(this.taskListApproved) return
        app.preloader.show()
        const vuserid = app.data.session.userid
        let model = ''
        if(app.data.session.source === "dingding") {
          model = 'kthrp.dingding.interface'
        } else if(app.data.session.source === "feishu") {
          model = 'kthrp.feishu.map.interface'
        } else {
          model = 'kthrp.wechat.map.interface'
        }
        const post_list_params = self.getListParams(null, vuserid, model, app.data.session.lang, "to_be_approved",'approved')
        app.request.postJSON(app.data.url, post_list_params, function (res) {
          res.result.task_list_approved.length =
            res.result.task_list_approved.length;
          app.preloader.hide()
          self.$setState({
            taskListApproved: res.result.task_list_approved,
            taskListApprovedCount: res.result.task_list_approved_count
          });
        })
      },
      ddRequestAuthCode: function (corpId) {
        dd.ready(function () {
          dd.runtime.permission.requestAuthCode({
            corpId: corpId, // 企业id
            onSuccess: function (info) {
              code = info.code // 通过该免登授权码可以获取用户身份
            }
          });
        });
      },
      getListParams: function (code, vuserid, model, lang, document_source,get_data_type) {
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        var obj = this.$app.data._config.send_type == 'map' ? this.$app.data.get_user_id_data : {}
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              model,
              "mobile_workflow_info_list",
              {
                get_data_type,
                auth_code: code,
                user_id: vuserid,
                status: "to_be_approved",
                url: location.href.split('#')[0],
                is_general_map: true,
                lang: lang,
                document_source: document_source,
                ...obj
              }
            ]
          }
        };

      },
      getDetailParams: function (task_id, model, is_general_map, map_approve_type, lang) {
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid; //登录用户
        var password = this.$app.data.password;
        var user_id = this.$app.data.session.userid; //权限用户
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              model,
              "mobile_workflow_info",
              {
                task_id,
                is_general_map,
                lang,
                user_id,
                map_approve_type,
              }
            ]
          }
        };
      },
      getFeishuDetailParams: function (task_id, model, is_general_map, map_approve_type, lang) {
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid; //登录用户
        var password = this.$app.data.password;
        var user_id = this.$app.data.session.userid; //权限用户
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              model,
              "mobile_workflow_info",
              {
                task_id,
                is_general_map,
                lang,
                feishu_userid: user_id,
                map_approve_type,
              }
            ]
          }
        };
      },
      getApproveParams: function (task_id, id, action, comment, model) {
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              model,
              "mobile_workflow_process",
              {
                task_id,
                operation: action,
                model: "kthrp.purchase.requisition",
                id,
                comment
              }
            ]
          }
        };
      },
      getBatchApproveParams: function (task_ids, action, comment, model) {
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              model,
              "mobile_workflow_process",
              {
                task_ids,
                operation: action,
                model: "kthrp.purchase.requisition"
              }
            ]
          }
        };
      },
      doApprove: function (e) {
        const {
          $$
        } = this;
        var page = $$('.page[data-name="approveddocument-index"]')[0].f7Page;
        var $$aBtn = $$(e.currentTarget).css("pointer-events", "none");
        setTimeout(function () {
          $$aBtn.css("pointer-events", "");
        }, 200);
        var task_id = parseInt(e.currentTarget.dataset.taskId);
        var id = parseInt(e.currentTarget.dataset.id);
        var action = e.currentTarget.dataset.action;
        var comment = $("#comment").val();
        if (page.app.data.session.source === "dingding") {
          var model = "kthrp.dingding.interface"
        } else if (page.app.data.session.source === "feishu") {
          var model = "kthrp.feishu.map.interface"
        } else {
          var model = "kthrp.wechat.map.interface"
        }
        var post_approve_params = this.getApproveParams(
          task_id,
          id,
          action,
          comment,
          model
        );
        var actionText = e.currentTarget.text;

        page.app.dialog.confirm('你确定要' + actionText + '吗？', function () {
          page.app.swipeout.close(page.app.swipeout.el);
          page.app.request.postJSON(
            page.app.data.url,
            post_approve_params,
            function (result_approve) {
              if (result_approve.result.result == "ok") {
                // page.router.back();
                var success_msg, error_msg;
                if (page.app.data.session.lang == "zh_CN") {
                  success_msg = "恭喜您,操作成功";
                  error_msg = "很遗憾,操作失败";
                } else if (page.app.data.session.lang == "en_US") {
                  success_msg = "Succeed";
                  error_msg = "Failed";
                }
                var toast = app.toast.create({
                  text: success_msg,
                  position: "top",
                  closeTimeout: 2000
                });
                toast.open();
                // page.router.navigate("/approveddocument/", {
                //   reloadCurrent: true,
                //   ignoreCache: true
                // });
                page.router.back('/approveddocument/', {
                  force: true
                })
              } else {
                var toast = app.toast.create({
                  text:result_approve.result.error_msg,
                  position: "top",
                  closeTimeout: 2000
                });
                toast.open();
                // page.router.navigate("/approveddocument/", {
                //   reloadCurrent: true,
                //   ignoreCache: true
                // });
                page.router.back('/approveddocument/', {
                  force: true
                })
              }
            }
          );
        }, function () {
          page.app.swipeout.close(page.app.swipeout.el);
        });

      },
      toDetailForm: function (e) {
        const {
          $$
        } = this;
        var page = $$('.page[data-name="approveddocument-index"]')[0].f7Page;
        var task_id = parseInt(e.currentTarget.dataset.taskId);
        var record_model = e.currentTarget.dataset.model;

        if (page.app.data.session.source === "dingding") {
          var model = "kthrp.dingding.interface"
        } else if (page.app.data.session.source === "feishu") {
          var model = "kthrp.feishu.map.interface"
        } else {
          var model = "kthrp.wechat.map.interface"
        }

        // var is_general_map = false;
        // if (record_model == "kthrp.purchase.supplier.requisition" || record_model == "kthrp.jiahui.cme.application") {}
        var is_general_map = true;
        var map_approve_type = e.currentTarget.dataset.maptype;


        var lang = page.app.data.session.lang;
        if(page.app.data.session.source === "feishu") {
          var post_detail_params = this.getFeishuDetailParams(task_id, model, is_general_map, map_approve_type, lang);
        } else {
          var post_detail_params = this.getDetailParams(task_id, model, is_general_map, map_approve_type, lang);
        }

        var type = e.currentTarget.dataset.type ? e.currentTarget.dataset.type : "";
        var is_tongyong = e.currentTarget.dataset.tongyong ? e.currentTarget.dataset.tongyong : "";

        var customerCode = e.currentTarget.dataset.code;
        // var status = e.currentTarget.dataset.status ? true : false;
        page.app.request.postJSON(
          page.app.data.url,
          post_detail_params,
          function (result_detail) {
            // result_detail.result.info.status = status;
            console.log(result_detail);
            result_detail.result.info.customerCode = customerCode;
            /*
            根据通用属性走不通的模板路线
            */
            if (result_detail.result.info.is_tongyong || result_detail.result.info.is_general_map) {
              page.router.navigate("/tongyong/", {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  info: result_detail.result.info,
                  lang: page.app.data.session.lang
                }
              });
            } else {
              var nModel = type == "" ? "/" + record_model + "/" : "/" + record_model + "." + type + "/";
              page.router.navigate(nModel, {
                reloadCurrent: false,
                ignoreCache: true,
                context: {
                  info: result_detail.result.info,
                  lang: page.app.data.session.lang
                }
              });
            }
          }
        );
      },
    },
    on: {
      pageInit: function (e, page) {
        var self = this;
        var progressbar = page.app.progressbar.show(
          ".demo-determinate-container"
        );



        $('[data-name="approveddocument-index"] .searchbar-done').hide();
        $('[data-name="approveddocument-index"] .search-icon-2').hide();

        $('[data-name="approveddocument-index"] .to-do-btn').on("click", function () {
          sbdone.disable();
          $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').hide();
          $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').show();
        });
        $('[data-name="approveddocument-index"] .done-btn').on("click", function () {
          sbtodo.disable();
          $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').show();
          $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').hide();
        });

        page.$pageEl.find("#tab1,#tab2").on("touchmove", function (e) {
          $(e.currentTarget)
            .parent()
            .parent()
            .prev()
            .find(".searchbar input")
            .blur();
        });

        page.app.request({
          async: false
        });
        page.app.request.postJSON(
          page.app.data.url, {
            jsonrpc: "2.0",
            method: "call",
            params: {
              service: "common",
              method: "login",
              args: [
                page.app.data.dbname,
                page.app.data.username,
                page.app.data.password
              ]
            }
          },
          function (login_result) {
            console.log(login_result);
            page.app.data.userid = login_result.result;
            var code = page.app.data.session.code;
            var vuserid = page.app.data.session.userid;
            if (page.app.data.session.source === "dingding") {
              /* dingding start */
              page.app.data.userid = login_result.result
              var vuserid = page.app.data.session.userid
              var model = "kthrp.dingding.interface"
              var post_list_params = self.getListParams(null, vuserid, model, page.app.data.session.lang, "to_be_approved",'to_be_approved')
              page.app.request.postJSON(page.app.data.url, post_list_params, function (result_list) {
                page.app.data.wxConfig = result_list
                console.log(result_list)
                if (result_list.result.result == 'error') {
                  // page.app.panel.get("left").destroy();
                  page.app.progressbar.hide(progressbar)
                  page.app.dialog.alert('当前企业钉钉标识未维护，请联系系统管理员。', 'KTHRP')
                  return
                }

                $$('.homepage-cover').css('display', 'none')
                if (result_list.result.kthrp_customer_code == 'kthrp') {
                  $$('.kthrp_menu').css('display', 'flex')
                }

                page.app.data.session.userid = result_list.result.userid
                result_list.result.task_list.length =
                  result_list.result.task_list.length;
                // result_list.result.task_list_approved.length =
                //   result_list.result.task_list_approved.length;
                self.$setState({
                  taskList: result_list.result.task_list,
                  taskListCount: result_list.result.task_list_count,
                  // taskListApproved: result_list.result.task_list_approved,
                  // taskListApprovedCount: result_list.result.task_list_approved_count,
                  lang: page.app.data.session.lang,
                  kthrp_customer_code:result_list.result.kthrp_customer_code
                });

                //未处理 搜索框实例
                var sbtodo = page.app.searchbar.create({
                  el: '.searchbar-to-do',
                  searchContainer: '.list-to-do',
                  searchIn: '.item-text-to-do',
                  notFoudEl: '.searchbar-not-found-to-do',
                });

                //已处理 搜索框实例
                var sbdone = page.app.searchbar.create({
                  el: ".searchbar-done",
                  searchContainer: ".list-done",
                  searchIn: ".item-text-done",
                  notFoudEl: ".searchbar-not-found-done",
                });

                $('[data-name="approveddocument-index"] .searchbar-done').hide();
                $('[data-name="approveddocument-index"] .search-icon-2').hide();

                $('[data-name="approveddocument-index"] .to-do-btn').on("click", function () {
                  sbdone.disable();
                  $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').hide();
                  $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').show();
                });
                $('[data-name="approveddocument-index"] .done-btn').on("click", function () {
                  sbtodo.disable();
                  $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').show();
                  $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').hide();
                });

                if (result_list.result.result === 'ok') {
                  page.app.progressbar.hide(progressbar)
                }
                // var key = parseInt(GetQueryString('state'))
                // if (key && page.app.data.flag) {
                //   var dbname = page.app.data.dbname
                //   var userid = page.app.data.userid
                //   var password = page.app.data.password
                //   page.app.request.postJSON(
                //     page.app.data.url, {
                //       jsonrpc: '2.0',
                //       method: 'call',
                //       params: {
                //         service: 'object',
                //         method: 'execute',
                //         args: [
                //           dbname,
                //           userid,
                //           password,
                //           'kthrp.dingding.interface',
                //           'mobile_workflow_info',
                //           {
                //             task_id: key
                //           }
                //         ]
                //       }
                //     },
                //     function (result_detail) {
                //       console.log(result_detail)
                //       var model = result_detail.result.info.model
                //       var type = result_detail.result.info.doc_type ? result_detail.result.info.doc_type : ''
                //       result_detail.result.info.customerCode = page.app.data.session.kthrp_customer_code
                //       var nModel = type == '' ? '/' + model + '/' : '/' + model + '.' + type + '/'
                //       if (result_detail.result.result == 'error') {
                //         page.app.dialog.alert('当前企业钉钉标识未维护，请联系系统管理员。', 'KTHRP')
                //         return
                //       }
                //       page.router.navigate(nModel, {
                //         reloadCurrent: false,
                //         ignoreCache: true,
                //         context: {
                //           info: result_detail.result.info
                //         }
                //       })
                //       page.app.data.flag = false
                //     }
                //   )
                // }
              })
              /** dingding end */
            } else if (page.app.data.session.source === "feishu") {
              /* feishu start */
              page.app.data.userid = login_result.result
              var vuserid = page.app.data.session.userid
              var model = "kthrp.feishu.map.interface"
              var post_list_params = self.getListParams(null, vuserid, model, page.app.data.session.lang, "to_be_approved",'to_be_approved')
              page.app.request.postJSON(page.app.data.url, post_list_params, function (result_list) {
                page.app.data.wxConfig = result_list
                console.log(result_list)
                if (result_list.result.result == 'error') {
                  // page.app.panel.get("left").destroy();
                  page.app.progressbar.hide(progressbar)
                  page.app.dialog.alert('当前企业飞书标识未维护，请联系系统管理员。', 'KTHRP')
                  return
                }

                $$('.homepage-cover').css('display', 'none')
                if (result_list.result.kthrp_customer_code == 'kthrp') {
                  $$('.kthrp_menu').css('display', 'flex')
                }

                page.app.data.session.userid = result_list.result.userid
                result_list.result.task_list.length =
                  result_list.result.task_list.length;
                // result_list.result.task_list_approved.length =
                //   result_list.result.task_list_approved.length;
                self.$setState({
                  taskList: result_list.result.task_list,
                  taskListCount: result_list.result.task_list_count,
                  // taskListApproved: result_list.result.task_list_approved,
                  // taskListApprovedCount: result_list.result.task_list_approved_count,
                  lang: page.app.data.session.lang,
                  kthrp_customer_code:result_list.result.kthrp_customer_code
                });

                //未处理 搜索框实例
                var sbtodo = page.app.searchbar.create({
                  el: '.searchbar-to-do',
                  searchContainer: '.list-to-do',
                  searchIn: '.item-text-to-do',
                  notFoudEl: '.searchbar-not-found-to-do',
                });

                //已处理 搜索框实例
                var sbdone = page.app.searchbar.create({
                  el: ".searchbar-done",
                  searchContainer: ".list-done",
                  searchIn: ".item-text-done",
                  notFoudEl: ".searchbar-not-found-done",
                });

                $('[data-name="approveddocument-index"] .searchbar-done').hide();
                $('[data-name="approveddocument-index"] .search-icon-2').hide();

                $('[data-name="approveddocument-index"] .to-do-btn').on("click", function () {
                  sbdone.disable();
                  $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').hide();
                  $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').show();
                });
                $('[data-name="approveddocument-index"] .done-btn').on("click", function () {
                  sbtodo.disable();
                  $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').show();
                  $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').hide();
                });

                if (result_list.result.result === 'ok') {
                  page.app.progressbar.hide(progressbar)
                }
                // var key = parseInt(GetQueryString('state'))
                // if (key && page.app.data.flag) {
                //   var dbname = page.app.data.dbname
                //   var userid = page.app.data.userid
                //   var password = page.app.data.password
                //   page.app.request.postJSON(
                //     page.app.data.url, {
                //       jsonrpc: '2.0',
                //       method: 'call',
                //       params: {
                //         service: 'object',
                //         method: 'execute',
                //         args: [
                //           dbname,
                //           userid,
                //           password,
                //           'kthrp.feishu.map.interface',
                //           'mobile_workflow_info',
                //           {
                //             task_id: key
                //           }
                //         ]
                //       }
                //     },
                //     function (result_detail) {
                //       console.log(result_detail)
                //       var model = result_detail.result.info.model
                //       var type = result_detail.result.info.doc_type ? result_detail.result.info.doc_type : ''
                //       result_detail.result.info.customerCode = page.app.data.session.kthrp_customer_code
                //       var nModel = type == '' ? '/' + model + '/' : '/' + model + '.' + type + '/'
                //       if (result_detail.result.result == 'error') {
                //         page.app.dialog.alert('当前企业飞书标识未维护，请联系系统管理员。', 'KTHRP')
                //         return
                //       }
                //       page.router.navigate(nModel, {
                //         reloadCurrent: false,
                //         ignoreCache: true,
                //         context: {
                //           info: result_detail.result.info
                //         }
                //       })
                //       page.app.data.flag = false
                //     }
                //   )
                // }
              })
              /** feishu end */
            } else {
              /* wechat start*/
              page.app.data.userid = login_result.result
              var code = page.app.data.session.code
              var vuserid = page.app.data.session.userid
              var model = "kthrp.wechat.map.interface"
              var post_list_params = self.getListParams(code, vuserid, model, page.app.data.session.lang, "to_be_approved",'to_be_approved')
              page.app.request.postJSON(page.app.data.url, post_list_params, function (result_list) {
                page.app.data.wxConfig = result_list
                console.log(result_list)
                if (result_list.result.result == 'error') {
                  // page.app.panel.get("left").destroy();
                  page.app.progressbar.hide(progressbar)
                  page.app.dialog.alert('当前企业微信标识未维护，请联系系统管理员。', 'KTHRP')
                  return
                }

                $$('.homepage-cover').css('display', 'none')
                if (result_list.result.kthrp_customer_code == 'kthrp') {
                  $$('.kthrp_menu').css('display', 'flex')
                }
                wx.config({
                  beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: result_list.result.signature_info.appid, // 必填，企业微信的corpID
                  timestamp: parseInt(result_list.result.signature_info.timestamp), // 必填，生成签名的时间戳
                  nonceStr: result_list.result.signature_info.noncestr, // 必填，生成签名的随机串
                  signature: result_list.result.signature_info.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                  jsApiList: ['chooseImage', 'scanQRCode', 'previewFile', 'getLocalImgData', 'uploadImage'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                })

                wx.ready(function () {
                  wx.checkJsApi({
                    jsApiList: ['chooseImage', 'scanQRCode', 'previewFile', 'getLocalImgData', 'uploadImage'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function (ress) {
                      console.log(ress)
                      // 以键值对的形式返回，可用的api值true，不可用为false
                      // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                    }
                  })
                })
                wx.error(function (res) {
                  console.log(res)
                  // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                })



                page.app.data.session.userid = result_list.result.userid
                result_list.result.task_list.length =
                  result_list.result.task_list.length;
                // result_list.result.task_list_approved.length =
                //   result_list.result.task_list_approved.length;
                self.$setState({
                  taskList: result_list.result.task_list,
                  taskListCount: result_list.result.task_list_count,
                  // taskListApproved: result_list.result.task_list_approved,
                  taskListApprovedCount: result_list.result.task_list_approved_count,
                  lang: page.app.data.session.lang,
                  kthrp_customer_code: result_list.result.kthrp_customer_code
                });

                //未处理 搜索框实例
                var sbtodo = page.app.searchbar.create({
                  el: '.searchbar-to-do',
                  searchContainer: '.list-to-do',
                  searchIn: '.item-text-to-do',
                  notFoudEl: '.searchbar-not-found-to-do',
                  on: {
                    search(sb, query, previousQuery) {
                      console.log(query, previousQuery);
                    }
                  }
                });
                var sbdone = page.app.searchbar.create({
                  el: ".searchbar-done",
                  searchContainer: ".list-done",
                  searchIn: ".item-text-done",
                  notFoudEl: ".searchbar-not-found-done",
                  on: {
                    search(sb, query, previousQuery) {
                      console.log(query, previousQuery);
                    }
                  }
                });
                $('[data-name="approveddocument-index"] .searchbar-done').hide();
                $('[data-name="approveddocument-index"] .search-icon-2').hide();

                $('[data-name="approveddocument-index"] .to-do-btn').on("click", function () {
                  sbdone.disable();
                  $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').hide();
                  $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').show();
                });
                $('[data-name="approveddocument-index"] .done-btn').on("click", function () {
                  sbtodo.disable();
                  $('[data-name="approveddocument-index"] .searchbar-done,[data-name="approveddocument-index"] .search-icon-2').show();
                  $('[data-name="approveddocument-index"] .searchbar-to-do,[data-name="approveddocument-index"] .search-icon-1').hide();
                });

                if (result_list.result.result === 'ok') {
                  page.app.progressbar.hide(progressbar)
                }
                // var key = parseInt(GetQueryString('state'))
                // if (key && page.app.data.flag) {
                //   var dbname = page.app.data.dbname
                //   var userid = page.app.data.userid
                //   var password = page.app.data.password
                //   page.app.request.postJSON(
                //     page.app.data.url, {
                //       jsonrpc: '2.0',
                //       method: 'call',
                //       params: {
                //         service: 'object',
                //         method: 'execute',
                //         args: [
                //           dbname,
                //           userid,
                //           password,
                //           'kthrp.wechat.map.interface',
                //           'mobile_workflow_info',
                //           {
                //             task_id: key
                //           }
                //         ]
                //       }
                //     },
                //     function (result_detail) {
                //       console.log(result_detail)
                //       var model = result_detail.result.info.model
                //       var type = result_detail.result.info.doc_type ? result_detail.result.info.doc_type : ''
                //       result_detail.result.info.customerCode = page.app.data.session.kthrp_customer_code
                //       var nModel = type == '' ? '/' + model + '/' : '/' + model + '.' + type + '/'
                //       if (result_detail.result.result == 'error') {
                //         page.app.dialog.alert('当前企业微信标识未维护，请联系系统管理员。', 'KTHRP')
                //         return
                //       }
                //       page.router.navigate(nModel, {
                //         reloadCurrent: false,
                //         ignoreCache: true,
                //         context: {
                //           info: result_detail.result.info
                //         }
                //       })
                //       page.app.data.flag = false
                //     }
                //   )
                // }
              })
              /* wechat end*/
            }
          }
        );
      },
      pageMounted: function (e, page) {
        console.log("pageMounted");
      },
      pageReinit: function (e, page) {
        //初始化置空 元数据中的form_data
        if ("form_data" in page.app.data.expense_data) {
          delete page.app.data.expense_data["form_data"];
        }
        console.log("pageReinit");
      },
      pageBeforeIn: function (e, page) {
        console.log("pageBeforeIn");
      },
      pageAfterIn: function (e, page) {
        console.log("pageAfterIn");
      },
      pageBeforeInOut: function (e, page) {
        console.log("pageBeforeInOut");
      },
      pageAfterOut: function (e, page) {
        console.log("pageAfterOut");
      },
      pageBeforeRemove: function (e, page) {
        console.log("pageBeforeRemove");
      }
    }
  }
}];