import $ from "dom7";
import _ from "lodash";
import style from "../styles/home.less";
// import Template7 from "template7";

import actionTemplateHtml from "../pages/actionTemplate.html";

export default {
  name: "/kthrp.contract.contract/aciton/",
  path: "/kthrp.contract.contract/aciton/",
  component: {
    template: actionTemplateHtml,
    style: style,
    data: () => {
      return {};
    },
    methods: {
      getTransferParams: function (
        model,
        operation,
        id,
        taskId,
        afterReturn,
        transferToNumber,
        designatedApprovers,
        backToId,
        comment,
        sourceModel,
        employees
      ) {
        var dbname = this.$app.data.dbname;
        var userid = this.$app.data.userid;
        var password = this.$app.data.password;
        return {
          jsonrpc: "2.0",
          method: "call",
          params: {
            service: "object",
            method: "execute",
            args: [
              dbname,
              userid,
              password,
              sourceModel,
              "mobile_workflow_process",
              {
                model: model,
                operation: operation,
                id: id,
                task_id: taskId,
                after_return: afterReturn,
                transfer_to: transferToNumber,
                designated_approvers: designatedApprovers,
                back_to_id: backToId, //回退id
                comment: comment,
                employees:employees
              }
            ]
          }
        };
      },
      doSubmit: function (e) {
        console.log("提交");
        const {
          $$
        } = this;
        var e = window.event || arguments[0];
        var page = $$('.page[data-name="actionTemplate"]')[0].f7Page;
        var operation = e.currentTarget.dataset.operation; //操作
        var comment = $("#comment").val(); //审批意见
        if (operation == "reject" && comment == "") {
          page.app.dialog.alert("请填写审批意见", "KTHRP");
        } else {
          var formData = page.app.form.convertToData("#actionForm");
          $$(e.currentTarget).css("pointer-events", "none"); //点击一次后失效 防止多次点击提交
          var sourceModel;
          var model = e.currentTarget.dataset.model; //模型名
          var id = parseInt(e.currentTarget.dataset.id) || "";
          var taskId = parseInt(e.currentTarget.dataset.taskId);
          var afterReturn;
          if (page.app.data.kthrp_customer_code == 'columbia') {
            afterReturn = e.currentTarget.dataset.returnAfter; //转交返回
          } else {
            if (formData.after_return) {
              afterReturn = formData.after_return[0] == 'on' ? "true" : "false";
            } else {
              afterReturn = "false";
            }
          }

          if(operation == 'add'){
            afterReturn = "false";
          }else if(operation == 'linkup'){
            afterReturn = "true";
          }else if(operation == 'transfer' && this.data){
            afterReturn = this.data.default_of_return ? "true" : "false";
          }

          var transferToNumber =
            "employee" in formData ? formData.employee : ""; //转交员工编号
          var employees =
            "employees" in formData ? formData.employees : ""; //加签或沟通员工编号数组
          var backToId = "node" in formData ? parseInt(formData.node) : ""; //回退员工编号
          var designatedApprovers =
            "designated_approvers" in formData ?
            formData.designated_approvers.map(function (item) {
              return parseInt(item);
            }) :
            ""; //指定审核人id组
            if (page.app.data.session.source === "dingding") {
              sourceModel = "kthrp.dingding.interface"
            } else if (page.app.data.session.source === "feishu") {
            sourceModel = "kthrp.feishu.map.interface"
          } else {
            sourceModel = "kthrp.wechat.map.interface"
          }
          var transferParams = this.getTransferParams(
            model,
            operation,
            id,
            taskId,
            afterReturn,
            transferToNumber,
            designatedApprovers,
            backToId, //回退id
            comment,
            sourceModel,
            employees
          );
          console.log(transferParams);
          page.app.request.postJSON(page.app.data.url, transferParams, function (
            transfer_result
          ) {
            console.log(transfer_result);
            var success_msg, error_msg;
            if (page.app.data.session.lang == "zh_CN") {
              success_msg = "恭喜您,操作成功";
              error_msg = "很遗憾,操作失败";
            } else if (page.app.data.session.lang == "en_US") {
              success_msg = "Succeed";
              error_msg = "Failed";
            }
            if (transfer_result.result.result == "ok") {
              // page.router.back();

              var toast = app.toast.create({
                text: success_msg,
                position: "top",
                closeTimeout: 2000
              });
              toast.open();
              // page.router.navigate("/approveddocument/", {
              //   // reloadCurrent: true,
              //   // ignoreCache: true
              // });
              page.router.back('/approveddocument/', {
                force: true
              })
            }
            //校验是否上传了附件
            else if (transfer_result.result.result == "check_file_error") {
              page.app.dialog.alert(transfer_result.result.error_msg, "KTHRP", function () {
                page.app.swipeout.close(page.app.swipeout.el);
                var toast = app.toast.create({
                  text: transfer_result.result.error_msg,
                  position: "top",
                  closeTimeout: 2000
                });
                toast.open();
                // page.router.navigate("/approveddocument/", {});
                page.router.back('/approveddocument/', {
                  force: true
                })
              });
            } else {
              app.dialog.alert(transfer_result.result.error_msg,() => {
                // page.router.navigate("/approveddocument/", {
                // // reloadCurrent: true,
                // // ignoreCache: true
                // });
                page.router.back('/approveddocument/', {
                  force: true
                })
              })
              // var toast = app.toast.create({
              //   text: transfer_result.result.error_msg,
              //   position: "top",
              //   closeTimeout: 2000
              // });
              // toast.open();
              // page.router.navigate("/approveddocument/", {
              //   // reloadCurrent: true,
              //   // ignoreCache: true
              // });
            }
          });
        }
      }
    },
    on: {
      pageInit: function (e, page) {}
    }
  }
};