//15 SIT
// let _config = {
//   jsonrpc: {
//     jsonrpc_url: "http://kthrp.karrytech.com:85/jsonrpc",
//     jsonrpc_dbname: "SIT",
//     jsonrpc_username: "kthrp_map",
//     jsonrpc_password: "1",
//   },
//   dd_config: {
//     corp_id: "dingd400cb9fd567ff3b35c2f4657eb6378f",
//   }
// };

//12 develop jiahui
let _config = {
  jsonrpc: {
    base_url: "http://hrpstaging.arioncc.cn",
    jsonrpc_dbname: "arion_1221",
    jsonrpc_username: "kthrp_map",
    jsonrpc_password: "Arion123",
  },
  dd_config: {
    corp_id: "dingd400cb9fd567ff3b35c2f4657eb6378f",
  }
};

//laowang
// let _config = {
//   jsonrpc: {
//     jsonrpc_url: "http://kthrp.karrytech.com:1051/jsonrpc",
//     jsonrpc_dbname: "dev111",
//     jsonrpc_username: "kthrp_map",
//     jsonrpc_password: "1",
//   },
//   dd_config: {
//     corp_id: "dingd400cb9fd567ff3b35c2f4657eb6378f",
//   }
// };



export default _config;
